<template>
    <div class="what_awaits_you">
        <div class="what_awaits_you_title">{{ $t('BuyProNormal_Question_ItemTitle') }}</div>
        <div class="what_awaits_you_list">
            <div class="what_await_you_item">
                <img @error="imageError" :src="`${$store.state.assetsUrl}/imgs/plan/tick.webp`" alt="tick">
                {{ $t('BuyProNormal_Answer1_ItemTitle') }}
            </div>
            <div class="what_await_you_item">
                <img @error="imageError" :src="`${$store.state.assetsUrl}/imgs/plan/tick.webp`" alt="tick">
                {{ $t('BuyProNormal_Answer2_ItemTitle') }}
            </div>
            <div class="what_await_you_item">
                <img @error="imageError" :src="`${$store.state.assetsUrl}/imgs/plan/tick.webp`" alt="tick">
                {{ $t('BuyProNormal_Answer3_ItemTitle') }}
            </div>
            <div class="what_await_you_item">
                <img @error="imageError" :src="`${$store.state.assetsUrl}/imgs/plan/tick.webp`" alt="tick">
                {{ $t('BuyProNormal_Answer4_ItemTitle') }}
            </div>
            <div class="what_await_you_item">
                <img @error="imageError" :src="`${$store.state.assetsUrl}/imgs/plan/tick.webp`" alt="tick">
                {{ $t('BuyProNormal_Answer5_ItemTitle') }}
            </div>
        </div>
    </div>
</template>

<script>
import { commonMixin } from '../../assets/mixin/common';
export default {
    mixins: [commonMixin],
    name: 'WhatAwaitsYou'
}
</script>

<style scoped lang="less">
.what_awaits_you {
    margin: 40px 18px;
    text-align: left;

    .what_awaits_you_title {
        font-size: 28px;
        font-family: 'AvenirNext';
        font-weight: 700;
        color: #444444;
        line-height: 38px;
        margin-bottom: 20px;
    }

    .what_await_you_item {
        display: flex;
        font-size: 14px;
        font-family: 'AvenirNext';
        font-weight: 500;
        color: #444444;
        line-height: 20px;
        margin-bottom: 4px;

        img {
            width: 20px;
            height: 20px;
            margin-right: 5px;
        }
    }

    img {
        pointer-events: none;
    }
}
</style>