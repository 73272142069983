<template>
    <div class="spread_animation" :style="{ 'width': width, 'height': height, }">
        <div class="spread_animation_button" @click="callback"
            :style="{ 'background-image': `linear-gradient(to right, ${startColor},${endColor})`, 'border-radius': height }">
            {{ text }}
        </div>
        <div class="corrugation"
            :style="{ 'background-image': `linear-gradient(to right, ${startColor},${endColor})`, 'border-radius': height }">
        </div>
    </div>
</template>

<script>
export default {
    name: 'spread_animation',
    props: {
        text: {
            type: String,
            default: () => '',
        },
        width: {
            type: String,
            default: () => '',
        },
        height: {
            type: String,
            default: () => '',
        },
        startColor: {
            type: String,
            default: () => '',
        },
        endColor: {
            type: String,
            default: () => '',
        },
        callback: {
            type: Function,
            default: () => { },
        }
    },

}
</script>

<style scoped lang="less">
.spread_animation {
    position: relative;

    .spread_animation_button {
        position: relative;
        font-size: 13px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #FFFFFF;
        width: 100%;
        height: 100%;
        cursor: pointer;
        z-index: 2;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .corrugation {
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 100%;
        /*动画属性名，也就是我们前面keyframes定义的动画名*/
        animation-name: ripple;
        /*动画持续时间*/
        animation-duration: 2s;
        /*动画频率，和transition-timing-function是一样的*/
        animation-timing-function: ease;
        /*动画延迟时间*/
        animation-delay: 0.1s;
        /*定义循环资料，infinite为无限次*/
        animation-iteration-count: infinite;
        /*定义动画方式*/
        animation-direction: normal;
        left: 0;
        top: 0;
    }

}

@keyframes ripple {
    0% {
        left: 0;
        top: 0;
        opacity: 1;
        width: 100%;
        height: 100%;
    }

    100% {
        left: -10px;
        top: -5px;
        opacity: 0;
        width: calc(100% + 20px);
        height: calc(100% + 10px);
    }
}
</style>