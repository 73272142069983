<template>
    <div class="plan">
        <!-- 倒计时模块 -->
        <countdown :discount="discount"></countdown>
        <!-- 收费 -->
        <plan-is-ready :mostPopularIndex="mostPopularIndex" :discount="discount" :priceList="activePrice" :paypal="paypal"
            elementKey="ready" :title="$t('PlanIsReady')"></plan-is-ready>
        <!-- plan模块 -->
        <plan-for-you></plan-for-you>
        <!-- 什么在等着你 -->
        <what-awaits-you></what-awaits-you>
        <!-- app相关评论 -->
        <comment-list></comment-list>
        <!-- 30天退款 -->
        <guarantee :showDescribe="false"></guarantee>
    </div>
</template>

<script>
import { requestPlanPrice } from '@/api/index.js'
import Countdown from '@/components/plan_components/countdown.vue'
import PlanIsReady from '@/components/plan_components/plan_is_ready.vue'
import PlanForYou from '@/components/plan_components/plan_for_you.vue'
import CommentList from '@/components/plan_components/comment_list.vue'
import Guarantee from '@/components/plan_components/guarantee.vue'
import WhatAwaitsYou from '@/components/plan_components/what_awaits_you.vue'
import { loadScript } from "@paypal/paypal-js";
import { myMixin } from '@/assets/mixin/index.js';
import { common } from '../assets/utils/common'
import { logFirebaseEvent } from '@/assets/log/log_manager.js'
import { Toast } from 'vant'
export default {
    name: 'plan',
    components: { Countdown, PlanIsReady, PlanForYou, CommentList, Guarantee, WhatAwaitsYou },
    mixins: [myMixin],
    data() {
        return {
            paypal: {},
            priceList: {},
            randomTimer: null,
            activePrice: [],
            discount: '',
            mostPopularIndex: 1,
        }
    },
    created() {
        this.$store.commit("setIsApp", true);
        this.getPlanInfo();
        this.checkCountdown();
        // 再次进入plan页面的数据埋点
        logFirebaseEvent('F_Web_Plan_PlanResult2');
    },
    mounted() {
        this.initPaypal();
    },
    methods: {
        // 检测倒计时
        checkCountdown() {
            let countdownCount = localStorage.getItem('countdownCount');
            // 如果倒计时结束就关闭
            if (countdownCount != null && countdownCount <= 0) {
                return;
            }
            if (countdownCount) {
                this.$store.dispatch('setCountdownCount', countdownCount);
            } else {
                this.$store.dispatch('setCountdownCount', '600');
            }
        },
        // 获取plan信息
        getPlanInfo() {
            requestPlanPrice({
                "slug": [
                    common.groupName1,
                    common.groupName2
                ]
            }).then(res => {
                if (res.errorCode == null) {
                    this.priceList = res;
                } else {
                    Toast(this.$t('NetworkErrorToast'));
                }
            });
        },
        // 初始化paypal
        initPaypal() {
            // 根据环境变量配置当前请求的client-id
            let clientId = process.env.VUE_APP_CLIENT_ID;
            loadScript({ "client-id": clientId, "vault": true, "intent": "subscription", 'components': 'buttons', 'locale': 'en_US' })
                .then((paypal) => {
                    this.paypal = paypal;
                })
                .catch((err) => {
                    console.error("failed to load the PayPal JS SDK script", err);
                });
        },
    },
    watch: {
        activePrice(val) {
            if (val.length == 0) return;
            if (val.length == 1) {
                this.mostPopularIndex = 0;
            } else {
                this.mostPopularIndex = 1;
            }
            let mostPopular = val[this.mostPopularIndex];
            this.discount = common.getDiscount(mostPopular);
        },
        priceList() {
            this.activePrice = this.priceList[common.groupName2]
        },
    },
    computed: {
        oneMonth() {
            return common.oneMonthPrice;
        }
    }
}
</script>

<style scoped lang="less">
.plan {
    max-width: 765px;
    margin: 0 auto 200px;
    overflow-x: hidden;
}
</style>